<template>
  <!--
    父组件在mounted调用init方法返回mapTalks地图实例，然后操作地图实例即可；
    地图实例如何操作可参考项目 对应功能 或 mapTalks 文档

    插槽：地图头部操作栏 prefix-action，可在原有的功能上添加新的功能按钮

    props属性：showTools 控制操作栏显示隐藏

    注意：操作地图实注意经纬度类型的转换
   -->
  <div class="base-map-box" ref="mapBoxRef">
    <div ref="mapDom" class="base-map"></div>
    <div class="tools-bar" v-if="showTools">
      <!-- 预留了功能按钮插槽 -->
      <slot name="prefix-action"></slot>

      <el-button
        :type="grayFilter?'primary':''"
        @click="enableGrayFilter"
        icon="el-icon-moon"
        circle
        title="暗色模式"
      />

      <el-button
        @click="fullScreen"
        icon="el-icon-full-screen"
        circle
        title="地图全屏"
      />

      <el-autocomplete
        ref="poiAutocomplete"
        v-model="queryAddress"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入地点"
        @select="handleSelect"
        clearable
        prefix-icon="el-icon-search"
        class="w-165 ml-10"
      ></el-autocomplete>

      <el-select
        v-if="!defaultLayerType"
        v-model="layerType"
        class="w-95 ml-10"
      >
        <el-option v-for="item in layerTypes" :key="item.value" v-bind="item" />
      </el-select>
    </div>
  </div>
</template>

<script>
import * as maptalks from 'maptalks'
import screenfull from 'screenfull'
import { getPlaces } from '@/apis/common'
import { mapState } from 'vuex'
import { formatLngLat } from './util'
export default {
  props: {
    showTools: {
      type: Boolean,
      default: true
    },
    minZoom: {
      type: Number,
      default: 4
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    zoom: {
      type: Number,
      default: 5
    },
    defaultLayerType: {
      type: String
    }
  },
  data () {
    return {
      queryAddress: '',
      mapIns: null,
      layerType: this.getLayerType(),
      layerTypes: [
        { value: 'baidu', label: '百度地图' },
        { value: 'gaode', label: '高德地图' },
        { value: 'tianditu', label: '天地图' }
      ],
      // 底图配置
      layers: {
        baidu: new maptalks.TileLayer('base', {
          urlTemplate: 'https://maponline{s}.bdimg.com/tile/?qt=vtile&x={x}&y={y}&z={z}&styles=pl&scaler=1&udt=20210506&showtext=1',
          subdomains: [0, 1, 2, 3]
        }),
        gaode: new maptalks.TileLayer('base', {
          urlTemplate: 'http://wprd{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&z={z}&x={x}&y={y}&scl=1&ltype=11',
          subdomains: ['01', '02', '03', '04']
        }),
        gaodeText: new maptalks.TileLayer('text', {
          urlTemplate: 'http://wprd04.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}&scl=1&ltype=4',
          subdomains: [0, 1, 2, 3]
        }),
        tianditu: new maptalks.TileLayer('base', {
          urlTemplate: 'http://t{s}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=d51ff014113891f4fe9a8c77c86ef6a9',
          subdomains: [1, 2, 3, 4]
        }),
        tiandituText: new maptalks.TileLayer('text', {
          crossOrigin: 'Anonymous',
          urlTemplate: 'https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=d51ff014113891f4fe9a8c77c86ef6a9',
          subdomains: [1, 2, 3, 4]
        })
      },
      // 图层滤镜
      grayFilter: false
    }
  },
  computed: {
    ...mapState({
      loginName: (state) => state.account.userInfo.loginName
    })
  },
  watch: {
    // 切换底图，抛出切换底图事件
    layerType (newVal, oldVal) {
      const mapIns = this.mapIns
      this.changeMapLayer(newVal, oldVal)
      this.$emit('change', { mapIns, layerType: newVal })

      // 针对某个账号缓存选择的地图底图
      const layers = JSON.stringify([
        { loginName: this.loginName, layerType: newVal }
      ])
      localStorage.setItem('layers', layers)
    }
  },
  methods: {
    // 取缓存的默认底图(如果props传递的有默认值，则用默认值)
    getLayerType () {
      if (this.defaultLayerType) {
        return this.defaultLayerType
      } else {
        let layers = localStorage.getItem('layers')
        layers = layers ? JSON.parse(layers) : []
        const { layerType } = layers.find(({ loginName }) => loginName === this.loginName) || { layerType: 'baidu' }
        this.layerType = layerType
        return layerType
      }
    },

    init () {
      const layerType = this.getLayerType()
      const mapIns = this.mapIns = new maptalks.Map(this.$refs.mapDom, {
        center: [120.462472, 36.148971],
        // center: [120.203885, 30.267029],
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom
      })
      this.changeMapLayer(layerType)
      return { mapIns, layerType }
    },

    // 修改底图
    changeMapLayer (type, oldType) {
      const layers = this.layers
      const mapIns = this.mapIns

      // 如果有文本图层(天地图)，移除文本图层
      const textLayer = mapIns.getLayer('text')
      if (textLayer) mapIns.removeLayer(textLayer)

      // 如果传了旧的type需要调整一下地图的中心坐标
      if (oldType) this.changeCenter(type, oldType, mapIns)
      if (type === 'baidu') {
        mapIns.setSpatialReference({ projection: 'baidu' })
        mapIns.setBaseLayer(layers.baidu)
      } else if (type === 'gaode') {
        mapIns.setSpatialReference(undefined)
        mapIns.setBaseLayer(layers.gaode)
        mapIns.addLayer(layers.gaodeText)
      } else if (type === 'tianditu') {
        mapIns.setSpatialReference(undefined)
        mapIns.setBaseLayer(layers.tianditu)
        mapIns.addLayer(layers.tiandituText)
      }
    },

    // 切换底图时,由于不同底图对应不同的经纬度类型,所以需要转换中心店的经纬度
    changeCenter (type, oldType, mapIns) {
      const center = mapIns.getCenter()
      const newCenter = formatLngLat(
        [center.x, center.y],
        oldType,
        type
      )
      mapIns.setCenter(newCenter)
    },

    // 深色模式
    enableGrayFilter () {
      const grayFilter = this.grayFilter = !this.grayFilter
      const options = {
        cssFilter: grayFilter ? 'sepia(100%) invert(90%)' : ''
      }
      this.layers.baidu.setOptions(options)
      this.layers.gaode.setOptions(options)
      this.layers.tianditu.setOptions(options)
      this.layers.tiandituText.setOptions(options)
      this.changeMapLayer(this.layerType)
    },

    handleSelect (item) {
      const layerType = this.layerType
      const newCenter = formatLngLat(
        [item.lng, item.lat],
        'baidu',
        layerType
      )
      this.mapIns.setCenterAndZoom(newCenter, 18)
    },

    querySearchAsync (keywords, cb) {
      let results = []
      if (keywords.trim().length > 1) {
        getPlaces({ keywords }).then((res) => {
          if (res.code === 200) {
            results = res.data.map(item => {
              return {
                value: item.poiName,
                lat: item.lat,
                lng: item.lng
              }
            })
          }
          cb(results)
        })
      } else {
        cb(results)
      }
    },

    // 全屏显示
    fullScreen () {
      if (!screenfull.enabled) {
        this.$message('您的浏览器不支持全屏')
      } else {
        screenfull.toggle(this.$refs.mapBoxRef)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-map-box {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;

  .base-map {
    width: 100%;
    height: 100%;
    min-height: 100px;
  }
  .tools-bar {
    position: absolute;
    height: 31px;
    top: 10px;
    right: 10px;
  }
}

// 去除左下角的 mapTalks 的logo
::v-deep .maptalks-attribution{
  display: none!important;
}
</style>
