import gcoord from 'gcoord'

// 格式化经纬度类型，类型值：baidu，gaode，tianditu
// lngLat Array
// oldType String
// newType String
export function formatLngLat (lngLat, oldType, newType) {
  const props = {
    baidu: 'BD09',
    gaode: 'GCJ02',
    tianditu: 'WGS84',
    gps: 'WGS84'
  }
  return gcoord.transform(
    lngLat, // 经纬度坐标
    gcoord[props[oldType]], // 当前坐标系
    gcoord[props[newType]] // 目标坐标系
  )
}
